<template>
  <div id="cart">
    <v-flex xs10 sm10 md10 offset-xs1 offset-sm1 offset-md1 class="mt-9">
      <v-flex xs12 class="pt-9"> 
        <!-- <span v-if="!isMobile" class="text">  </span> -->
        <p class="p-text mt-2 mb-3"> {{ cartNumber }} {{ $t('cart.title')}} </p>
        <!-- <Voucher :total="total"/> -->
      </v-flex>
      <v-layout wrap class="cart mb-16" v-if="!isMobile">
        <v-flex xs12 sm6 md3 class="pt-3" v-for="(item, index) in cart" :key="index">
          <img v-bind:src="item.data.url_image[0]" class="cart-img" :alt="item.data.url_image[0]">
          <p class="text-2"> {{ item.data.product_name }}</p>
          <!-- <p class="p-text-gray"> {{ item.size.sku_product }}</p> -->
          <p v-if="item.isBundling" class="p-text-gray"> {{ addComma(item.data.selling_price) }} IDR </p>
          <p v-else class="p-text-gray"> {{ addComma(item.size.selling_price) }} IDR </p>
          <p class="text-2" v-if="!item.isBundling"> 
            {{ item.size.ukuran }}
          </p>
          <div v-else>
            <div v-for="(item, i) in item.bundling" :key="i"> 
              <p class="text-2 mb-0">{{ item.product_name }}</p>
              <p class="p-text-gray mb-0">{{ item.variant.ukuran }} - {{item.qty}} pcs</p>
            </div>
          </div>
          <div class="cart-button mt-5">
            <span class="cart-button-item" @click="minus(index)"> - </span>
            <span class="cart-button-item pr-2 pl-2" > {{ item.quantity }} </span>
            <span class="cart-button-item" @click="addToCart(index)"> + </span>
          </div>
          <p class="cart-button mt-9" @click="trash(index)"> {{ $t('delete')}} </p>
        </v-flex>
      </v-layout>
      
      <v-layout wrap class="cart" v-else>
        <v-flex xs12 class="pt-3" v-for="(item, index) in cart" :key="index">
          <v-layout wrap>
            <v-flex xs6 sm6 class="mb-4">
              <img v-bind:src="item.data.url_image[0]" class="cart-img-m" :alt="item.data.url_image[0]">
            </v-flex>
            <v-flex xs6 sm6>
              <p class="text-2"> {{ item.data.product_name }}</p>
              <!-- <p class="p-text-gray"> {{ item.size.sku_product }}</p> -->
              <p v-if="item.isBundling" class="p-text-gray"> {{ addComma(item.data.selling_price) }} IDR </p>
              <p v-else class="p-text-gray"> {{ addComma(item.size.selling_price) }} IDR </p>
              <p class="text-2" v-if="!item.isBundling"> 
                {{ item.size.ukuran }}
              </p>
              <div v-else>
                <div v-for="(item, i) in item.bundling" :key="i"> 
                  <p class="text-2 mb-0">{{ item.product_name }}</p>
                  <p class="p-text-gray mb-0">{{ item.variant.ukuran }} - {{item.qty}} pcs</p>
                </div>
              </div>
              <div class="cart-button mt-5">
                <span class="cart-button-item" @click="minus(index)"> - </span>
                <span class="cart-button-item pr-2 pl-2" > {{ item.quantity }} </span>
                <span class="cart-button-item" @click="addToCart(index)"> + </span>
              </div>
              <p class="cart-button mt-9" @click="trash(index)"> {{ $t('delete')}} </p>
            </v-flex>
          </v-layout>
          <v-divider class="mb-2"></v-divider>
        </v-flex>
        <v-flex>
          <div class="amount">
            <table class="tbl">
              <tr>
                <td class="p-text-gray">Subtotal</td>
                <td class="p-text-gray pr"> {{ addComma(total)}} IDR</td>
              </tr>
              <tr>
                <td class="p-text"> <b> Total </b> </td>
                <td class="p-text pr"> <b> {{ addComma(total)}} IDR</b> </td>
              </tr>
            </table>
          </div>
          <v-btn class="mt-5 tw mb-custom" tile color="black" width="100%" height="44px" @click="checkCart()" :disabled="cart.length === 0">
            <span class="btn-text"> {{ $t('continue')}} </span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-footer v-if="!isMobile"
      elevation="10"
      fixed
      color="white"
      style="footer-cart"
    >
      <v-col
        class="text-center"
        cols="4"
      >
        <v-btn class="pl" outlined tile width="50%" height="44px" @click="back()">
          <span class="btn-text"> {{ $t('back')}} </span>
        </v-btn>
      </v-col>
      <v-col
        class="text-center"
        cols="4"
      >
      <p class="p-text"> TOTAL </p>
      <span class="text"> {{ addComma(total) }} IDR</span>
      </v-col>
      <v-col
        class="text-center"
        cols="4"
      >
       <v-btn class="pr tw" tile color="black" width="50%" height="44px" @click="checkCart()" :disabled="cart.length === 0">
          <span class="btn-text"> {{ $t('continue')}} </span>
        </v-btn>
      </v-col>
    </v-footer>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'
  // import Voucher from '@/components/Voucher'

  export default {
    name: 'Cart',
    // components: { Voucher },
    computed: {
      ...mapState([
        'isLogin',
        'token',
        'isMobile',
        'cartNumber',
        'cart'
      ])
    },
    data () {
      return {
        total: 0
      }
    },
    methods: {
      minus (index) {
        if (Number(this.cart[index].quantity) > 1) {
          this.cart[index].quantity = Number(this.cart[index].quantity) - 1
          this.setTotalAmount()
          this.$store.commit('setCart', this.cart)
        } else {
          this.trash(index);
        }
      },
      trash (index) {
        this.cart.splice(index, 1)
        this.setTotalAmount()
        this.$store.commit('setCart', this.cart)
      },
      setTotalAmount () {
        this.total = 0
        this.cart.map(item => {
          if (item.isBundling) {
            this.total += (Number(item.data.selling_price) * item.quantity)
          } else {
            this.total += (Number(item.size.selling_price) * item.quantity)
          }
        })
      },
      addToCart (index) {
        if (this.cart[index].isBundling) {
          this.cart[index].quantity = Number(this.cart[index].quantity) + 1
          this.$store.commit('setCart', this.cart)
          this.setTotalAmount()
        } else {
          this.$store.commit('setIsLoading', true)

          axios.defaults.headers = {
            id_brand: process.env.VUE_APP_BRAND
          }

          axios.post('/api/v1/orders/add_to_cart', {
              sku_product: this.cart[index].size.sku_product,
              qty: 1,
              old_qty: this.cart[index].quantity,
              total_cart_qty: this.cartNumber
            })
            .then(response => {
              this.$store.commit('setSnackbar', true)
              this.$store.commit('setText', response.data.message)
              this.cart[index].quantity = Number(this.cart[index].quantity) + 1
              this.$store.commit('setCart', this.cart)
              // this.showSizeList = true
              this.setTotalAmount()

              if (process.env.VUE_APP_PIXEL_TIKTOK) {
                window.ttq.track('AddToCart', { 
                  content_type: 'product',
                  content_id: this.cart[index].isBundling ? this.cart[index].data.product_code : this.cart[index].size.sku_product,
                  value:  this.cart[index].isBundling ? this.cart[index].data.selling_price : this.cart[index].size.selling_price,
                  description: this.cart[index].data.product_name,
                  currency: 'IDR',
                  quantity: 1
                })
              }

            })
            .catch(error => {
              this.errorHandling(error)
            })
            .finally(() => {
              this.$store.commit('setIsLoading', false)
            })
        }
      },
      getSkuProduct (item) {
        if (item.isBundling) {
          return item.bundling.map(value => {
            return {
              sku_product: value.variant.sku_product,
              qty : value.qty
            }
          })
          // return []
        } else {
          return item.size.sku_product
        }
      },
      // getSkuBundling (item) {
      //   if (item.isBundling) {
      //     return item.bundling.map(value => {
      //       return {
      //         sku_product: value.variant.sku_product,
      //         qty : value.qty
      //       }
      //     })
      //   } else {
      //     return []
      //   }
      // },
      checkCart () {
        if (!this.isLogin) {
          this.$router.push({ name: 'Login'})
        } else {
          this.$store.commit('setIsLoading', true)

          let products = this.cart.map(item => {
            return {
              product_code: item.data.product_code,
              sku_product: this.getSkuProduct(item),
              // sku_bundling: this.getSkuBundling(item),
              qty: item.quantity
            }
          })

          axios.defaults.headers = {
            id_brand: process.env.VUE_APP_BRAND,
            token: this.token
          }

          // api/v1/orders/cart_new
          axios.post('/api/v1/orders/cart', {
              products: products
          })
            .then(response => {
              this.$store.commit('setSnackbar', true)
              this.$store.commit('setText', response.data.message)
              if (response.data.data.jenis_paket && Array.isArray(response.data.data.jenis_paket)) {
                this.$store.commit('setShipping', response.data.data.jenis_paket[0])

                // const shipping = response.data.data.jenis_paket
                // const defaultShipping = shipping.find(item => { return item.provider_code === "SICEPAT" && item.service === "REG"})
              
                // this.$store.commit('setShipping', defaultShipping || response.data.data.jenis_paket[0])
                this.$store.commit('setShippingOptions', response.data.data.jenis_paket)
              } else {
                this.$store.commit('setShipping', response.data.data.jenis_paket)
              }
              
              this.$store.commit('setDelivery', response.data.data.alamat_penerima)
              this.toPage()
            })
            .catch(error => {
              this.errorHandling(error)
            })
            .finally(() => {
              this.$store.commit('setIsLoading', false)
            })
        }
      },
      toPage () {
        this.$router.push({ name: 'Checkout' });
      },
      back () {
        this.$router.back()
      },
      hitfbpixel () {
        let products = this.cart.map(item => {
          return {
            id: item.size.sku_product,
            sku_product: item.size.sku_product,
            size: item.size.ukuran,
            product_name: item.data.product_name,
            quantity: item.quantity
          }
        })

        if (products.length == 0 ) {
          products = [{ id: 'nodata', quantity: 0}]
        }

        this.$analytics.fbq.event('AddToCart', {
          contents: products,
          content_type: 'product',
          value: this.total,
          currency: 'IDR'
        })
      }
    },
    mounted () {
      this.setTotalAmount()
      this.setMetaInfo(null, this.$route.name)

      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsMemberPage', false)
      this.$store.commit('setIsLoginPage', true)
      this.$store.commit('setActivePage', this.$t('cart.title'))
    }
  }
</script>

<style scoped>
  .footer-cart {
    padding: 5px;
    z-index: 100;
  }
  .tbl {
    width: 100%;
  }

  .pr {
    float: right;
  }

  .pl {
    float: left;
  }

  .text {
    font-size: 24px;
    color: #000000;
    font-weight: 600;
  }

  .text-2 {
    margin: 0;
    font-size: 14px;
    color: #000000;
    font-weight: bold;
  }

  .p-text {
    margin: 0;
    font-size: 14px;
    color: #000000;
  }

  .p-text-gray {
    margin: 0;
    font-size: 12px;
    color: #808080;
  }

  .cart {
    color: #FFFFFF;
    margin-top: 5%;
  }

  .cart-img {
    width: 276px;
    height: 400px;
    object-fit: cover;
  }

  .cart-img-m {
    width: 125px;
    height: 200px;
    object-fit: cover;
  }

  .cart-button {
    font-size: 0.7rem;
    color: #000000;
    width: 100%;
    cursor: pointer;
  }

  .cart-button-item {
    border: 1px solid #C0C0C0;
    padding: 5px;
    text-align: center;
    cursor: pointer;
  }

  .amount {
    background-color: #f3f2f2;
    padding: 10px;
  }

  .tw {
    color: white;
  }

  .mb-custom {
    margin-bottom: 100px;
  }

</style>
